import axios from "axios";
import { userActionTypes } from "actions/types";
import { isClient } from "utils/index";
var jwtLocalStorage = function jwtLocalStorage(store) {
    return function (next) {
        return function (action) {
            if (action.type === userActionTypes.STORE_JWT) {
                var nextMiddleware = next(action);
                var nextState = store.getState();
                if (isClient) {
                    localStorage.setItem("token", nextState.user.token);
                    axios.defaults.headers.common["authorization"] = nextState.user.token;
                }
                return nextMiddleware;
            }
            return next(action);
        };
    };
};
export default jwtLocalStorage;
