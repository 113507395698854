import { action } from "typesafe-actions";
import { favoritesActionTypes as actionTypes } from "actions/types";
export var fetchFavorites = function fetchFavorites() {
    return action(actionTypes.FETCH_USER_FAVORITES);
};
export var fetchFavoritesCompleted = function fetchFavoritesCompleted(modelsIDs, semiCarsIDs) {
    return action(actionTypes.FETCH_USER_FAVORITES_COMPLETED, {
        modelsIDs: modelsIDs,
        semiCarsIDs: semiCarsIDs
    });
};
export var toggleNewCarFavorite = function toggleNewCarFavorite(id) {
    return action(actionTypes.TOGGLE_NEW_CAR, {
        id: id
    });
};
export var toggleUsedCarFavorite = function toggleUsedCarFavorite(id) {
    return action(actionTypes.TOGGLE_USED_CAR, {
        id: id
    });
};
export var toggleNewCarFavoriteCompleted = function toggleNewCarFavoriteCompleted(id) {
    return action(actionTypes.TOGGLE_NEW_CAR_FAVORITE_COMPLETED, {
        id: id
    });
};
export var toggleUsedCarFavoriteCompleted = function toggleUsedCarFavoriteCompleted(id) {
    return action(actionTypes.TOGGLE_USED_CAR_FAVORITE_COMPLETED, {
        id: id
    });
};
export var fetchYear = function fetchYear(year) {
    return action(actionTypes.FETCH_YEAR, {
        year: year
    });
};
export var fetchYearComplete = function fetchYearComplete(year) {
    return action(actionTypes.FETCH_YEAR_COMPLETE, {
        year: year
    });
};
export var toggleUsedComparator = function toggleUsedComparator(id) {
    return action(actionTypes.TOGGLR_USED_COMPARATOR, {
        id: id
    });
};
export var toggleUsedComparatorComplete = function toggleUsedComparatorComplete(id) {
    return action(actionTypes.TOGGLR_USED_COMPARATOR_COMPLETE, {
        id: id
    });
};
