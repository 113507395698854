import { action } from "typesafe-actions";
import { searchFilterActionTypes as actionTypes } from "./types";
export var toggleBrand = function toggleBrand(brand) {
    return action(actionTypes.TOGGLE_BRAND, brand);
};
export var toggleYear = function toggleYear(year) {
    return action(actionTypes.TOGGLE_YEAR, year);
};
export var setBrand = function setBrand(brand) {
    return action(actionTypes.SET_BRAND, brand);
};
export var setPrice = function setPrice(price) {
    return action(actionTypes.SET_PRICE, price);
};
export var setDeposit = function setDeposit(deposit) {
    return action(actionTypes.SET_DEPOSIT, deposit);
};
export var setMonthlyPayment = function setMonthlyPayment(payment) {
    return action(actionTypes.SET_MONTHLY_PAYMENT, payment);
};
export var setCarType = function setCarType(type) {
    return action(actionTypes.SET_CAR_TYPE, type);
};
export var setFilter = function setFilter(filter) {
    return action(actionTypes.SET_FILTER, filter);
};
export var setKm = function setKm(kms) {
    return action(actionTypes.SET_KM, kms);
};
export var setModel = function setModel(model) {
    return action(actionTypes.SET_MODEL, model);
};
export var setPriceOrdering = function setPriceOrdering(priceOrdering) {
    return action(actionTypes.SET_PRICE_ORDERING, priceOrdering);
};
export var setUserQuery = function setUserQuery(query) {
    return action(actionTypes.SET_USER_QUERY, query);
};
export var setCity = function setCity(city) {
    return action(actionTypes.SET_CITY, city);
};
export var setState = function setState(state) {
    return action(actionTypes.SET_STATE, state);
};
export var setActivePage = function setActivePage(state) {
    return action(actionTypes.SET_ACTIVE_PAGE, state);
};
export var resetActivePage = function resetActivePage() {
    return action(actionTypes.RESET_ACTIVE_PAGE);
};
export var toggleBodytype = function toggleBodytype(type) {
    return action(actionTypes.TOGGLE_BODY_TYPE, type);
};
export var clearFilter = function clearFilter() {
    return action(actionTypes.CLEAR_FILTER);
};
export var clearDeposit = function clearDeposit() {
    return action(actionTypes.CLEAR_DEPOSIT);
};
export var clearMonthlyPayment = function clearMonthlyPayment() {
    return action(actionTypes.CLEAR_MONTHLY_PAYMENT);
};
export var clearModel = function clearModel() {
    return action(actionTypes.CLEAR_MODEL);
};
export var clearKM = function clearKM() {
    return action(actionTypes.CLEAR_KM);
};
export var clearPrice = function clearPrice() {
    return action(actionTypes.CLEAR_PRICE);
};
export var clearCarBodyType = function clearCarBodyType() {
    return action(actionTypes.CLEAR_CAR_BODY_TYPE);
};
export var clearCity = function clearCity() {
    return action(actionTypes.CLEAR_CITY);
};
