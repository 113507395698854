export var transformImages = function transformImages(images) {
    var exteriorImages = images.filter(function (img) {
        return img.type === "ext";
    }).map(function (img) {
        return {
            id: img.id,
            url: img.url,
            type: "exterior"
        };
    });
    var interiorImages = images.filter(function (img) {
        return img.type === "int";
    }).map(function (img) {
        return {
            id: img.id,
            url: img.url,
            type: "interior"
        };
    });
    return exteriorImages.concat(interiorImages);
};
