export var validateEmail = function validateEmail(email) {
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};
export var hasValidAddress = function hasValidAddress(address) {
    var state = address.state, city = address.city, colony = address.colony, street = address.street, postalCode = address.postalCode, ext = address.ext;
    return !!(state && city && colony && street && postalCode && ext);
};
export var hasAllDataForSignup = function hasAllDataForSignup(name, lastName, email, password, passConfirm) {
    return name && lastName && email && password && passConfirm && password.length > 0 && passConfirm === password;
};
export var queryHasBrand = function queryHasBrand(query) {
    var brandRegex = /Acura|Audi|BMW|Buick|Cadillac|Chevrolet|Chrysler|Fiat|Ford|GMC|Honda|Hyundai|Infiniti|Jaguar|KIA|LandRover|Mazda|Mercedes|MG|MiniCooper|Mitsubishi|Nissan|Peugeot|Porsche|Renault|Seat|Smart|Subaru|Suzuki|Toyota|Volkswagen|Volvo/i;
    return query.match(brandRegex);
};
