import { onLoginTimeout, onLogout, storeJWT } from "actions/user";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import axios from "axios";
import { isClient } from "utils/index";
var updateTokenCookie = function updateTokenCookie(newToken) {
    if (isClient) {
        var cookies = new Cookies();
        cookies.remove("token", {
            path: "/"
        });
        cookies.set("token", newToken, {
            maxAge: 3600000,
            path: "/"
        });
    }
};
var updateExpiredJWT = function updateExpiredJWT(err, store) {
    var currentToken = store.getState().user.token;
    var token = err.response.headers.authorization;
    if (!token || currentToken === token)
        return;
    store.dispatch(storeJWT(token));
    updateTokenCookie(token);
};
var handleInvalidJWT = function handleInvalidJWT(err, store) {
    var serverResponse = err.response;
    if (serverResponse && serverResponse.data && serverResponse.data.code && serverResponse.data.code === 440) {
        var _store$getState = store.getState(), user = _store$getState.user;
        if (user.isLoggedIn) {
            store.dispatch(onLoginTimeout());
        }
    }
};
var showUnauthModal = function showUnauthModal(err) {
    var serverResponse = err.response;
    if (serverResponse && serverResponse.data && serverResponse.data.code && serverResponse.data.code === 401) {
        Swal.fire("Ups!", "Para realizar esta acción necesitas confirmar tu cuenta", "warning");
    }
};
var logedInBackNotInFront = function logedInBackNotInFront(err, store) {
    var serverResponse = err.response;
    if (serverResponse && serverResponse.data && serverResponse.data.code && serverResponse.data.code === 401) {
        store.dispatch(onLogout());
    }
};
var mainErrInterceptor = function mainErrInterceptor(store) {
    axios.interceptors.response.use(undefined, function (err) {
        updateExpiredJWT(err, store);
        handleInvalidJWT(err, store);
        logedInBackNotInFront(err, store);
        showUnauthModal(err);
        return Promise.reject(err);
    });
};
var updateCookieFromToken = function updateCookieFromToken(store) {
    if (!isClient)
        return;
    axios.interceptors.request.use(function (config) {
        var _store$getState2 = store.getState(), user = _store$getState2.user;
        var cookies = new Cookies();
        var cookieToken = cookies.get("token");
        if (user.token !== cookieToken) {
            cookies.set("token", user.token, {
                maxAge: 3600000,
                path: "/"
            });
        }
        return config;
    });
};
export default function setupClientInterceptors(store) {
    mainErrInterceptor(store);
    updateCookieFromToken(store);
}
