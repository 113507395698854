import _objectSpread from "@babel/runtime/helpers/objectSpread";
import { sellYourCarActionTypes as actionTypes } from "actions/types";
var initialState = {
    year: 2020
};
var sellYourCarReducer = function sellYourCarReducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
        case actionTypes.GET_YEAR_SUCCESS:
            console.log("YEARS DESDE EL REDUCER", action.payload);
            return _objectSpread({}, state, {
                year: action.payload
            });
        default:
            return state;
    }
};
export default sellYourCarReducer;
