import { action } from "typesafe-actions";
import { userActionTypes as actionTypes } from "./types";
export var storeFingerprint = function storeFingerprint(hash) {
    var meta = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        forceRequest: false
    };
    return action(actionTypes.STORE_FINGERPRINT, hash, meta);
};
export var storeJWT = function storeJWT(token) {
    return action(actionTypes.STORE_JWT, token);
};
export var onLoginSuccess = function onLoginSuccess(firstName, lastName, id, role, email, phoneNumber, hasUpdatedData) {
    var data = {
        firstName: firstName,
        lastName: lastName,
        id: id,
        role: role,
        email: email,
        phoneNumber: phoneNumber,
        hasUpdatedData: hasUpdatedData
    };
    return action(actionTypes.ON_LOGIN_SUCCESS, data);
};
export var onLogout = function onLogout() {
    return action(actionTypes.ON_LOGOUT);
};
export var onLogoutSuccess = function onLogoutSuccess() {
    return action(actionTypes.ON_LOGOUT_SUCCESS, null, {
        forceRequest: true
    });
};
export var setUpdatedData = function setUpdatedData(hasUpdatedData) {
    return action(actionTypes.SET_UPDATED_DATA, hasUpdatedData);
};
export var setPaymentOrders = function setPaymentOrders(orders) {
    return action(actionTypes.SET_PAYMENT_ORDERS, orders);
};
export var onLoginTimeout = function onLoginTimeout() {
    return action(actionTypes.ON_LOGIN_TIMEOUT);
};
