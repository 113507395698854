export var userActionTypes;
(function (userActionTypes) {
    userActionTypes["STORE_FINGERPRINT"] = "USER_STORE_FINGERPRINT";
    userActionTypes["STORE_JWT"] = "USER_STORE_JWT";
    userActionTypes["ON_LOGOUT"] = "USER_ON_LOGOUT";
    userActionTypes["ON_LOGIN_SUCCESS"] = "USER_ON_LOGIN_SUCCESS";
    userActionTypes["ON_LOGOUT_SUCCESS"] = "USER_ON_LOGOUT_SUCCESS";
    userActionTypes["ON_LOGIN_TIMEOUT"] = "USER_ON_LOGIN_TIMEOUT";
    userActionTypes["SET_UPDATED_DATA"] = "USER_SET_UPDATED_DATA";
    userActionTypes["SET_PAYMENT_ORDERS"] = "USER_SET_PAYMENT_ORDERS";
})(userActionTypes || (userActionTypes = {}));
export var searchFilterActionTypes;
(function (searchFilterActionTypes) {
    searchFilterActionTypes["TOGGLE_YEAR"] = "FILTER_TOGGLE_YEAR";
    searchFilterActionTypes["TOGGLE_BRAND"] = "FILTER_TOGGLE_BRAND";
    searchFilterActionTypes["TOGGLE_BODY_TYPE"] = "FILTER_TOGGLE_BODY_TYPE";
    searchFilterActionTypes["SET_BRAND"] = "FILTER_SET_BRAND";
    searchFilterActionTypes["SET_PRICE"] = "FILTER_SET_PRICE";
    searchFilterActionTypes["SET_DEPOSIT"] = "FILTER_SET_DEPOSIT";
    searchFilterActionTypes["SET_MONTHLY_PAYMENT"] = "FILTER_SET_MONTHLY_PAYMENT";
    searchFilterActionTypes["SET_CAR_TYPE"] = "FILTER_SET_CAR_TYPE";
    searchFilterActionTypes["SET_FILTER"] = "FILTER_SET_FILTER";
    searchFilterActionTypes["SET_KM"] = "FILTER_SET_KM";
    searchFilterActionTypes["SET_MODEL"] = "FILTER_SET_MODEL";
    searchFilterActionTypes["SET_PRICE_ORDERING"] = "FILTER_SET_PRICE_ORDERING";
    searchFilterActionTypes["SET_USER_QUERY"] = "FILTER_SET_USER_QUERY";
    searchFilterActionTypes["SET_CITY"] = "FILTER_SET_CITY";
    searchFilterActionTypes["SET_STATE"] = "FILTER_SET_STATE";
    searchFilterActionTypes["SET_ACTIVE_PAGE"] = "FILTER_SET_ACTIVE_PAGE";
    searchFilterActionTypes["RESET_ACTIVE_PAGE"] = "FILTER_RESET_ACTIVE_PAGE";
    searchFilterActionTypes["SET_CAR_BODY_TYPE"] = "FILTER_SET_CAR_BODY_TYPE";
    searchFilterActionTypes["CLEAR_DEPOSIT"] = "FILTER_CLEAR_DEPOSIT";
    searchFilterActionTypes["CLEAR_MONTHLY_PAYMENT"] = "FILTER_CLEAR_MONTHLY_PAYMENT";
    searchFilterActionTypes["CLEAR_MODEL"] = "FILTER_CLEAR_MODEL";
    searchFilterActionTypes["CLEAR_FILTER"] = "FILTER_CLEAR_FILTER";
    searchFilterActionTypes["CLEAR_PRICE"] = "FILTER_CLEAR_PRICE";
    searchFilterActionTypes["CLEAR_KM"] = "FILTER_CLEAR_KM";
    searchFilterActionTypes["CLEAR_CAR_BODY_TYPE"] = "FILTER_CLEAR_CAR_BODY_TYPE";
    searchFilterActionTypes["CLEAR_CITY"] = "FILTER_CLEAR_CITY";
})(searchFilterActionTypes || (searchFilterActionTypes = {}));
export var sellYourCarActionTypes;
(function (sellYourCarActionTypes) {
    sellYourCarActionTypes["GET_YEAR"] = "GET_YEAR";
    sellYourCarActionTypes["GET_YEAR_SUCCESS"] = "GET_YEAR_SUCCESS";
})(sellYourCarActionTypes || (sellYourCarActionTypes = {}));
export var favoritesActionTypes;
(function (favoritesActionTypes) {
    favoritesActionTypes["FETCH_USER_FAVORITES"] = "FETCH_USER_FAVORITES";
    favoritesActionTypes["FETCH_USER_FAVORITES_COMPLETED"] = "FETCH_USER_FAVORITES_COMPLETED";
    favoritesActionTypes["TOGGLE_NEW_CAR"] = "TOGGLE_NEW_CAR";
    favoritesActionTypes["TOGGLE_USED_CAR"] = "TOGGLE_USED_CAR";
    favoritesActionTypes["TOGGLE_NEW_CAR_FAVORITE_COMPLETED"] = "TOGGLE_NEW_CAR_FAVORITE_COMPLETED";
    favoritesActionTypes["TOGGLE_USED_CAR_FAVORITE_COMPLETED"] = "TOGGLE_USED_CAR_FAVORITE_COMPLETED";
    favoritesActionTypes["FETCH_YEAR"] = "FETCH_YEAR";
    favoritesActionTypes["FETCH_YEAR_COMPLETE"] = "FETCH_YEAR_COMPLETE";
    favoritesActionTypes["TOGGLR_USED_COMPARATOR"] = "TOGGLR_USED_COMPARATOR";
    favoritesActionTypes["TOGGLR_USED_COMPARATOR_COMPLETE"] = "TOGGLR_USED_COMPARATOR_COMPLETE";
    favoritesActionTypes["FETCH_COMPARATOR"] = "FETCH_COMPARATOR";
})(favoritesActionTypes || (favoritesActionTypes = {}));
export var checkoutActionTypes;
(function (checkoutActionTypes) {
    checkoutActionTypes["SET_CAR_TYPE"] = "CHECKOUT_SET_CAR_TYPE";
    checkoutActionTypes["SET_CAR_INFO"] = "CHECKOUT_SET_CAR_INFO";
    checkoutActionTypes["SET_CAR_COLOR"] = "CHECKOUT_SET_CAR_COLOR";
    checkoutActionTypes["SET_PAYMENT_MONTHS"] = "CHECKOUT_SET_PAYMENT_MONTHS";
    checkoutActionTypes["SET_DOWNPAYMENT"] = "CHECKOUT_SET_DOWNPAYMENT";
    checkoutActionTypes["SET_ADDITIONAL_OPTIONS"] = "CHECKOUT_SET_ADDITIONAL_OPTIONS";
    checkoutActionTypes["ADD_ADDITIONAL_OPTION"] = "CHECKOUT_ADD_ADDITIONAL_OPTION";
    checkoutActionTypes["REMOVE_ADDITIONAL_OPTION"] = "CHECKOUT_REMOVE_ADDITIONAL_OPTION";
    checkoutActionTypes["ADD_PROMO_OPTION"] = "CHECKOUT_ADD_PROMO_OPTION";
    checkoutActionTypes["REMOVE_PROMO_OPTION"] = "CHECKOUT_REMOVE_PROMO_OPTION";
    checkoutActionTypes["SET_ALL_CAR_DATA"] = "CHECKOUT_SET_ALL_CAR_DATA";
    checkoutActionTypes["SET_VERSION"] = "CHECKOUT_SET_VERSION";
    checkoutActionTypes["SET_NEW_CAR_STEP"] = "CHECKOUT_SET_NEW_CAR_STEP";
    checkoutActionTypes["SET_USED_CAR_STEP"] = "CHECKOUT_SET_USED_CAR_STEP";
    checkoutActionTypes["RESET_CHECKOUT_STATE"] = "CHECKOUT_RESET_STATE";
})(checkoutActionTypes || (checkoutActionTypes = {}));
export var carQuoteActionTypes;
(function (carQuoteActionTypes) {
    carQuoteActionTypes["SET_YEAR"] = "QUOTE_SET_YEAR";
    carQuoteActionTypes["SET_BRAND"] = "QUOTE_SET_BRAND";
    carQuoteActionTypes["SET_MODEL"] = "QUOTE_SET_MODEL";
    carQuoteActionTypes["SET_VERSION"] = "QUOTE_SET_VERSION";
    carQuoteActionTypes["SET_KM"] = "QUOTE_SET_KM";
    carQuoteActionTypes["SET_COLOR"] = "QUOTE_SET_COLOR";
    carQuoteActionTypes["SET_BASIC_INFO"] = "QUOTE_SET_BASIC_INFO";
    carQuoteActionTypes["SET_VERSION_CODE"] = "QUOTE_SET_VERSION_CODE";
    carQuoteActionTypes["SET_ZIP_CODE"] = "SET_ZIP_CODE";
    carQuoteActionTypes["SET_USER_EMAIL"] = "QUOTE_SET_USER_EMAIL";
    carQuoteActionTypes["SET_USER_TELEPHONE"] = "QUOTE_SET_USER_TELEPHONE";
    carQuoteActionTypes["SET_KOTIZATIONS_ID"] = "SET_KOTIZATIONS_ID";
    carQuoteActionTypes["SET_NAME"] = "QUOTE_SET_NAME";
})(carQuoteActionTypes || (carQuoteActionTypes = {}));
export var newCarDetailActionTypes;
(function (newCarDetailActionTypes) {
    newCarDetailActionTypes["SET_DATA"] = "NEW_CAR_DETAIL_SET_DATA";
    newCarDetailActionTypes["RESET_DATA"] = "NEW_CAR_DETAIL_RESET_DATA";
})(newCarDetailActionTypes || (newCarDetailActionTypes = {}));
export var usedCarDetailActionTypes;
(function (usedCarDetailActionTypes) {
    usedCarDetailActionTypes["SET_DATA"] = "USED_CAR_DETAIL_SET_DATA";
    usedCarDetailActionTypes["RESET_DATA"] = "USED_CAR_DETAIL_RESET_DATA";
})(usedCarDetailActionTypes || (usedCarDetailActionTypes = {}));
export var bbvaActionsType;
(function (bbvaActionsType) {
    bbvaActionsType["TASA_INTERES"] = "TASA_INTERES";
})(bbvaActionsType || (bbvaActionsType = {}));
export var insuranceTypes;
(function (insuranceTypes) {
    insuranceTypes["INSURANCE_SET_DATA"] = "INSURANCE_SET_DATA";
    insuranceTypes["INSURANCE_BRANDS_PER_YEAR"] = "INSURANCE_BRANDS_PER_YEAR";
    insuranceTypes["INSURANCE_MODELS_PER_BRAND"] = "INSURANCE_MODELS_PER_BRAND";
    insuranceTypes["INSURANCE_VERSION_PER_MODEL"] = "INSURANCE_VERSION_PER_MODEL";
    insuranceTypes["INSURANCE_PERSONAL_DATA"] = "INSURANCE_PERSONAL_DATA";
    insuranceTypes["INSURANCE_PERSONAL_DATA_PERSON"] = "INSURANCE_PERSONAL_DATA_PERSON";
    insuranceTypes["INSURANCE_GET_VEHICLE_DATA"] = "INSURANCE_GET_VEHICLE_DATA";
    insuranceTypes["INSURANCE_PRICES"] = "INSURANCE_PRICES";
    insuranceTypes["INSURANCE_PACKAGE_BASIC"] = "INSURANCE_PACKAGE_BASIC";
    insuranceTypes["INSURANCE_PACKAGE_AMPLIA"] = "INSURANCE_PACKAGE_AMPLIA";
    insuranceTypes["INSURANCE_PACKAGE_PLUS"] = "INSURANCE_PACKAGE_PLUS";
    insuranceTypes["INSURANCE_PRICES_ERROR"] = "INSURANCE_PRICES_ERROR";
    insuranceTypes["INSURANCE_BACK_TO_PERSONAL_DATA"] = "INSURANCE_BACK_TO_PERSONAL_DATA";
    insuranceTypes["INSURANCE_BUY"] = "INSURANCE_BUY";
    insuranceTypes["INSURANCE_BUY_ERROR"] = "INSURANCE_BUY_ERROR";
    insuranceTypes["INSURANCE_PERSONAL_FORM1"] = "INSURANCE_PERSONAL_FORM1";
    insuranceTypes["INSURANCE_PERSONAL_FORM2"] = "INSURANCE_PERSONAL_FORM2";
    insuranceTypes["INSURANCE_PERSONAL_FORM3"] = "INSURANCE_PERSONAL_FORM3";
    insuranceTypes["INSURANCE_PAYMENT_DATA"] = "INSURANCE_PAYMENT_DATA";
    insuranceTypes["INSURANCE_PAYMENT_DATA_ERROR"] = "INSURANCE_PAYMENT_DATA_ERROR";
    insuranceTypes["INSURANCES_CLEANT_ROUTES"] = "INSURANCES_CLEANT_ROUTES";
    insuranceTypes["INSURANCE_PAYMENT_SUCCESS"] = "INSURANCE_PAYMENT_SUCCESS";
    insuranceTypes["INSURANCE_PAYMENT_ERROR"] = "INSURANCE_PAYMENT_ERROR";
    insuranceTypes["INSURANCE_FARRERA_ID"] = "INSURANCE_FARRERA_ID";
    insuranceTypes["INSURANCE_COMPLETE_DATA_SUCCESS"] = "INSURANCE_COMPLETE_DATA_SUCCESS";
    insuranceTypes["INSURANCE_COMPLETE_DATA_ERROR"] = "INSURANCE_COMPLETE_DATA_ERROR";
    insuranceTypes["INSURANCE_PAYMENT_FORM"] = "INSURANCE_PAYMENT_FORM";
    insuranceTypes["INSURANCES_GET_ALL_AGENCIES"] = "INSURANCES_GET_ALL_AGENCIES";
    insuranceTypes["INSURANCE_SENT_MAIL"] = "INSURANCE_SENT_MAIL";
    insuranceTypes["SET_AGENCY"] = "SET_AGENCY";
    insuranceTypes["CONSESIONARY_DATA"] = "CONSESIONARY_DATA";
    insuranceTypes["CONSESIONARY_DATA_ERROR"] = "CONSESIONARY_DATA_ERROR";
    insuranceTypes["INSURANCE_RETRY"] = "INSURANCE_RETRY";
    insuranceTypes["RESENT_MAIL"] = "RESENT_MAIL";
    insuranceTypes["RESENT_MAIL_ERROR"] = "RESENT_MAIL_ERROR";
    insuranceTypes["GET_TYPE_BUSINESS"] = "GET_TYPE_BUSINESS";
    insuranceTypes["GET_TYPE_BUSINESS_ERROR"] = "GET_TYPE_BUSINESS_ERROR";
    insuranceTypes["RESET_RESENT_MAIL"] = "RESET_RESENT_MAIL";
})(insuranceTypes || (insuranceTypes = {}));
export var footerContectTypes;
(function (footerContectTypes) {
    footerContectTypes["SEND_MAIL"] = "SEND_MAIL";
    footerContectTypes["RESET_SEND_MAIL"] = "SEND_MAIL";
})(footerContectTypes || (footerContectTypes = {}));
export var cotizationIntelimotor;
(function (cotizationIntelimotor) {
    cotizationIntelimotor["COTIZATION_INTELIMOTOR_GET_BRAND"] = "COTIZATION_INTELIMOTOR_GET_BRAND";
    cotizationIntelimotor["COTIZATION_INTELIMOTOR_GET_BRAND_ERROR"] = "COTIZATION_INTELIMOTOR_GET_BRAND_ERROR";
    cotizationIntelimotor["COTIZATION_INTELIMOTOR_GET_MODEL"] = "COTIZATION_INTELIMOTOR_GET_MODEL";
    cotizationIntelimotor["COTIZATION_INTELIMOTOR_GET_MODEL_ERROR"] = "COTIZATION_INTELIMOTOR_GET_MODEL_ERROR";
    cotizationIntelimotor["COTIZATION_INTELIMOTOR_GET_YEAR"] = "COTIZATION_INTELIMOTOR_GET_YEAR";
    cotizationIntelimotor["COTIZATION_INTELIMOTOR_GET_YEAR_ERROR"] = "COTIZATION_INTELIMOTOR_GET_YEAR_ERROR";
    cotizationIntelimotor["COTIZATION_INTELIMOTOR_GET_VERSION"] = "COTIZATION_INTELIMOTOR_GET_VERSION";
    cotizationIntelimotor["COTIZATION_INTELIMOTOR_GET_VERSION_ERROR"] = "COTIZATION_INTELIMOTOR_GET_VERSION_ERROR";
    cotizationIntelimotor["COTIZATION_INTELIMOTOR_GET_AGENCIES"] = "COTIZATION_INTELIMOTOR_GET_AGENCIES";
    cotizationIntelimotor["COTIZATION_INTELIMOTOR_GET_AGENCIES_ERROR"] = "COTIZATION_INTELIMOTOR_GET_AGENCIES_ERROR";
    cotizationIntelimotor["COTIZATION_INTELIMOTOR_CREATE_VALUATION"] = "COTIZATION_INTELIMOTOR_CREATE_VALUATION";
    cotizationIntelimotor["COTIZATION_INTELIMOTOR_CREATE_VALUATION_ERROR"] = "COTIZATION_INTELIMOTOR_CREATE_VALUATION_ERROR";
    cotizationIntelimotor["COTIZATION_INTELIMOTOR_SAVE_BRANDID"] = "COTIZATION_INTELIMOTOR_SAVE_BRANDID";
    cotizationIntelimotor["COTIZATION_INTELIMOTOR_SAVE_MODELID"] = "COTIZATION_INTELIMOTOR_SAVE_MODELID";
    cotizationIntelimotor["COTIZATION_INTELIMOTOR_SAVE_YEARID"] = "COTIZATION_INTELIMOTOR_SAVE_YEARID";
    cotizationIntelimotor["COTIZATION_INTELIMOTOR_SAVE_VERSIONID"] = "COTIZATION_INTELIMOTOR_SAVE_VERSIONID";
    cotizationIntelimotor["COTIZATION_INTELIMOTOR_SAVE_AGENCY"] = "COTIZATION_INTELIMOTOR_SAVE_AGENCY";
    cotizationIntelimotor["COTIZATION_INTELIMOTOR_SAVE_AGENCY_DATA"] = "COTIZATION_INTELIMOTOR_SAVE_AGENCY_DATA";
    cotizationIntelimotor["COTIZATION_INTELIMOTOR_SAVE_AGENCIES_LIST"] = "COTIZATION_INTELIMOTOR_SAVE_AGENCIES_LIST";
    cotizationIntelimotor["COTIZATION_INTELIMOTOR_SAVE_USER"] = "COTIZATION_INTELIMOTOR_SAVE_USER";
    cotizationIntelimotor["COTIZATION_CLEAN_ERROR"] = "COTIZATION_CLEAN_ERROR";
    cotizationIntelimotor["COTIZATION_CLEAN_MODELS"] = "COTIZATION_CLEAN_MODELS";
    cotizationIntelimotor["COTIZATION_CLEAN_YEARS"] = "COTIZATION_CLEAN_YEARS";
    cotizationIntelimotor["COTIZATION_CLEAN_VERSIONS"] = "COTIZATION_CLEAN_VERSIONS";
})(cotizationIntelimotor || (cotizationIntelimotor = {}));
