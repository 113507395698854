import _objectSpread from "@babel/runtime/helpers/objectSpread";
import { insuranceTypes as actionTypes } from 'actions/types';
var initialState = {
    brandsPerYear: [],
    modelsPerBrand: [],
    versionsPerModel: [],
    personalData: [],
    vehicleSelect: [],
    insurancesPrices: [],
    insurancePackageBasic: {},
    insurancePackageAmplia: {},
    insurancePackagePlus: {},
    nextPage: null,
    prevPage: null,
    error: null,
    insuranceSelect: [],
    nextPageForm: null,
    personalForm1: [],
    personalForm2: [],
    personalForm3: [],
    paymentFormData: [],
    paymentData: null,
    paymentDataError: [],
    paymentSuccess: [],
    paymentSuccessConfirm: false,
    paymentError: null,
    paymentErrorConfirm: false,
    farreraId: [],
    completeSuccess: null,
    completeError: null,
    allAgencies: [],
    agencyFollowUp: null,
    consesionaryDataIMG: null,
    resentMail: null,
    sentMail: false,
    allTypeBusiness: []
};
var insuranceReducer = function insuranceReducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
        case actionTypes.INSURANCE_SET_DATA:
            return _objectSpread({}, action.payload);
        case actionTypes.INSURANCE_PERSONAL_DATA_PERSON:
            return _objectSpread({}, state, {
                personalData: action.payload,
                nextPage: true
            });
        case actionTypes.INSURANCE_BRANDS_PER_YEAR:
            return _objectSpread({}, state, {
                brandsPerYear: action.payload
            });
        case actionTypes.INSURANCE_SENT_MAIL:
            return _objectSpread({}, state, {
                sentMail: action.payload
            });
        case actionTypes.INSURANCE_MODELS_PER_BRAND:
            return _objectSpread({}, state, {
                modelsPerBrand: action.payload
            });
        case actionTypes.INSURANCE_VERSION_PER_MODEL:
            return _objectSpread({}, state, {
                versionsPerModel: action.payload
            });
        case actionTypes.INSURANCE_PRICES:
            return _objectSpread({}, state, {
                insurancesPrices: action.payload,
                nextPage: true
            });
        case actionTypes.INSURANCE_PACKAGE_BASIC:
            return _objectSpread({}, state, {
                insurancePackageBasic: action.payload,
                nextPage: true
            });
        case actionTypes.INSURANCE_PACKAGE_AMPLIA:
            return _objectSpread({}, state, {
                insurancePackageAmplia: action.payload,
                nextPage: true
            });
        case actionTypes.INSURANCE_PACKAGE_PLUS:
            return _objectSpread({}, state, {
                insurancePackagePlus: action.payload,
                nextPage: true
            });
        case actionTypes.INSURANCE_PRICES_ERROR:
            return _objectSpread({}, state, {
                error: action.payload,
                prevPage: true,
                nextPage: false
            });
        case actionTypes.INSURANCES_CLEANT_ROUTES:
            return _objectSpread({}, state, {
                nextPage: false,
                nextPageForm: false,
                error: null
            });
        case actionTypes.INSURANCE_GET_VEHICLE_DATA:
            return _objectSpread({}, state, {
                prevPage: true,
                nextPage: false,
                vehicleSelect: action.payload,
                error: null
            });
        case actionTypes.INSURANCE_BACK_TO_PERSONAL_DATA:
            return _objectSpread({}, state, {
                insurancesPrices: []
            });
        case actionTypes.INSURANCE_BUY:
            return _objectSpread({}, state, {
                prevPage: true,
                nextPageForm: true,
                insuranceSelect: action.payload
            });
        case actionTypes.INSURANCE_BUY_ERROR:
            return _objectSpread({}, state, {
                prevPage: true,
                nextPageForm: false,
                insuranceSelect: action.payload
            });
        case actionTypes.INSURANCE_PERSONAL_FORM1:
            return _objectSpread({}, state, {
                personalForm1: action.payload
            });
        case actionTypes.INSURANCE_PERSONAL_FORM2:
            return _objectSpread({}, state, {
                personalForm2: action.payload
            });
        case actionTypes.INSURANCE_PERSONAL_FORM3:
            return _objectSpread({}, state, {
                personalForm3: action.payload
            });
        case actionTypes.INSURANCE_PAYMENT_DATA:
            return _objectSpread({}, state, {
                paymentData: action.payload
            });
        case actionTypes.INSURANCE_PAYMENT_DATA_ERROR:
            return _objectSpread({}, state, {
                paymentDataError: action.payload
            });
        case actionTypes.INSURANCE_PAYMENT_SUCCESS:
            return _objectSpread({}, state, {
                paymentSuccess: action.payload,
                paymentSuccessConfirm: true,
                paymentErrorConfirm: false
            });
        case actionTypes.INSURANCE_PAYMENT_ERROR:
            return _objectSpread({}, state, {
                paymentError: action.payload,
                paymentErrorConfirm: true,
                paymentSuccessConfirm: false
            });
        case actionTypes.INSURANCE_FARRERA_ID:
            return _objectSpread({}, state, {
                farreraId: action.payload
            });
        case actionTypes.INSURANCE_COMPLETE_DATA_SUCCESS:
            return _objectSpread({}, state, {
                completeSuccess: action.payload
            });
        case actionTypes.INSURANCE_COMPLETE_DATA_ERROR:
            return _objectSpread({}, state, {
                completeError: action.payload || 'Error al intentar emitir'
            });
        case actionTypes.INSURANCE_RETRY:
            return _objectSpread({}, state, {
                completeError: null
            });
        case actionTypes.INSURANCE_PAYMENT_FORM:
            return _objectSpread({}, state, {
                paymentFormData: action.payload
            });
        case actionTypes.INSURANCES_GET_ALL_AGENCIES:
            return _objectSpread({}, state, {
                allAgencies: action.payload
            });
        case actionTypes.SET_AGENCY:
            return _objectSpread({}, state, {
                agencyFollowUp: action.payload
            });
        case actionTypes.CONSESIONARY_DATA:
            return _objectSpread({}, state, {
                consesionaryDataIMG: action.payload
            });
        case actionTypes.CONSESIONARY_DATA_ERROR:
            return _objectSpread({}, state, {
                completeError: action.payload
            });
        case actionTypes.RESENT_MAIL:
            return _objectSpread({}, state, {
                resentMail: action.payload,
                error: null
            });
        case actionTypes.RESENT_MAIL_ERROR:
            return _objectSpread({}, state, {
                error: action.payload
            });
        case actionTypes.RESET_RESENT_MAIL:
            return _objectSpread({}, state, {
                resentMail: null
            });
        case actionTypes.GET_TYPE_BUSINESS:
            return _objectSpread({}, state, {
                allTypeBusiness: action.payload
            });
        case actionTypes.GET_TYPE_BUSINESS_ERROR:
            return _objectSpread({}, state, {
                error: action.payload
            });
        default:
            return state;
    }
};
export default insuranceReducer;
