import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { API_URL } from "utils/api";
import axios from "axios";
import { transformImages } from "utils/fetch";
export var getLatestPrice = function getLatestPrice(carPrices) {
    var lastPrice, index = carPrices.length - 1;
    for (; index >= 0; index--) {
        if (carPrices[index].price) {
            lastPrice = carPrices[index];
            break;
        }
    }
    return lastPrice ? lastPrice.price : 0;
};
var getCoverPhoto = function getCoverPhoto(carData) {
    if (carData.cover)
        return carData.cover;
    return carData.semiCarImages && carData.semiCarImages.length > 0 ? carData.semiCarImages[0].url : "";
};
export var getLocation = function getLocation(carData) {
    var withState = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    if (carData.agency && carData.agency.address) {
        var _carData$agency$addre = carData.agency.address, city = _carData$agency$addre.city, state = _carData$agency$addre.state;
        return withState ? "".concat(city, ", ").concat(state) : city;
    }
    if (carData.address) {
        return carData.address.city;
    }
    return "";
};
var transformData = function transformData(carsData) {
    return carsData.map(function (data) {
        var carPrices = data.carPrices, promoRelations = data.promoRelations, badge = data.badge, status = data.status;
        var price = carPrices && carPrices.length ? getLatestPrice(data.carPrices) : 0;
        var promos = promoRelations && promoRelations.length > 0 ? promoRelations : [];
        var usedCar = {
            id: data.id,
            modelId: data.id,
            brandName: data.brand,
            modelName: data.model,
            version: data.version,
            price: price,
            promos: promos,
            badge: badge,
            status: status,
            carInfo: data.agency,
            year: data.year,
            kms: data.km,
            isRecommended: data.isRecommended,
            imageCoverUrl: getCoverPhoto(data),
            reserved: !!data.reservated,
            type: data.type,
            location: getLocation(data),
            transmission: data.transmission,
            description: data.description
        };
        return usedCar;
    });
};
var transformDetails = function transformDetails(details) {
    return details.map(function (data) {
        var detail = {
            id: data.id,
            description: data.description,
            area: data.area,
            type: data.type,
            photo: data.photo,
            pos: {
                x: data.x,
                y: data.y
            }
        };
        return detail;
    });
};
var transformSingleCar = function transformSingleCar(data) {
    var promoRelations = data.promoRelations;
    var semiCarInfo = data.maxiPublicaDescription || {};
    var carInfo = {
        transmissionType: data.transmission,
        numberOfSeats: semiCarInfo.numberOfSeats || "5",
        motorType: semiCarInfo.motorType,
        motorPower: semiCarInfo.power,
        motorPowerRPM: semiCarInfo.motorPowerRPM,
        motorTorque: semiCarInfo.torque,
        motorTorqueRPM: semiCarInfo.motorTorqueRPM,
        motorEfficiency: semiCarInfo.motorEfficiency,
        motorCylinders: semiCarInfo.cylinders,
        transmission: semiCarInfo.transmission,
        suspensionType: semiCarInfo.suspensionType,
        direction: semiCarInfo.direction,
        brakes: semiCarInfo.brakes,
        traction: semiCarInfo.traction,
        AC: semiCarInfo.AC,
        seats: semiCarInfo.seats,
        vestments: semiCarInfo.vesture,
        windows: semiCarInfo.windows,
        headlights: semiCarInfo.headlights,
        rearlights: semiCarInfo.rearlights,
        foglights: semiCarInfo.foglights,
        wheelsSize: semiCarInfo.diameterWheels,
        wheels: semiCarInfo.wheels,
        wiperWasher: semiCarInfo.wiperWasher,
        connectivity: semiCarInfo.connectivity,
        SO: semiCarInfo.SO,
        screen: semiCarInfo.screen,
        turnOn: semiCarInfo.turnOn,
        audioSystem: semiCarInfo.audioSystem,
        airbags: semiCarInfo.airbags,
        ABS: semiCarInfo.ABS,
        sensors: semiCarInfo.sensors,
        antitheft: semiCarInfo.antitheft,
        passengers: semiCarInfo.passengers,
        trunkSize: semiCarInfo.trunkSize,
        fuelTank: semiCarInfo.fuelTank,
        interiorProportions: semiCarInfo.interiorProportions,
        exteriorHeight: semiCarInfo.dimensionsHeight,
        exteriorLength: semiCarInfo.dimensionsLength,
        exteriorWidth: semiCarInfo.dimensionsWidth,
        kilometers: data.km,
        //Maxiconfort
        lumbarAdjustment: semiCarInfo.lumbarAdjustment,
        cruiseControl: semiCarInfo.cruiseControl,
        memorySeats: semiCarInfo.memorySeats,
        adjustablePedals: semiCarInfo.adjustablePedals,
        rainSensor: semiCarInfo.rainSensor,
        sensorLaneChange: semiCarInfo.sensorLaneChange,
        brakeAssist: semiCarInfo.brakeAssist,
        antenna: semiCarInfo.antenna,
        speakers: semiCarInfo.speakers,
        remoteControlAudio: semiCarInfo.remoteControlAudio,
        auxiliaryInput: semiCarInfo.auxiliaryInput,
        screenEntertainment: semiCarInfo.screenEntertainment,
        usbPort: semiCarInfo.usbPort,
        satelitalRadio: semiCarInfo.satelitalRadio,
        cdPlayer: semiCarInfo.cdPlayer,
        startButton: semiCarInfo.startButton,
        heatedSeats: semiCarInfo.heatedSeats,
        carEntertaiment: semiCarInfo.carEntertaiment,
        automaticDoor: semiCarInfo.automaticDoor,
        keylessEntry: semiCarInfo.keylessEntry,
        systemVoice: semiCarInfo.systemVoice,
        parkingSensor: semiCarInfo.parkingSensor,
        navigationSystem: semiCarInfo.navigationSystem,
        parkingCamera: semiCarInfo.parkingCamera,
        rearDefrost: semiCarInfo.rearDefrost,
        bluetooth: semiCarInfo.bluetooth,
        //MaxipublicaExterior
        colorExt: semiCarInfo.colorExt,
        aleron: semiCarInfo.aleron,
        rearWiper: semiCarInfo.rearWiper,
        sunroof: semiCarInfo.sunroof,
        spoilers: semiCarInfo.spoilers,
        glassRoof: semiCarInfo.glassRoof,
        //Maxipublica seguridad
        alarm: semiCarInfo.alarm,
        childSeatAnchor: semiCarInfo.childSeatAnchor,
        airBagPassenger: semiCarInfo.airBagPassenger,
        airBag: semiCarInfo.airBag,
        sideAirBags: semiCarInfo.sideAirBags,
        airBagCourtain: semiCarInfo.airBagCourtain,
        kneeAirbags: semiCarInfo.kneeAirbags,
        backSeatAirBag: semiCarInfo.backSeatAirBag,
        frontSeatBelts: semiCarInfo.frontSeatBelts,
        stabilitySystem: semiCarInfo.stabilitySystem,
        tractionControl: semiCarInfo.tractionControl,
        abs: semiCarInfo.abs,
        discBrakes: semiCarInfo.discBrakes,
        glassSecurity: semiCarInfo.glassSecurity,
        centralLock: semiCarInfo.centralLock,
        gpsLocator: semiCarInfo.gpsLocator,
        //Maxipublica interior
        vesture: semiCarInfo.vesture,
        colorInt: semiCarInfo.colorInt,
        vanityMirror: semiCarInfo.vanityMirror,
        doorMirriors: semiCarInfo.doorMirriors,
        dimmingMirrior: semiCarInfo.dimmingMirrior,
        airConditioning: semiCarInfo.airConditioning,
        airConditioningDoubleZone: semiCarInfo.airConditioningDoubleZone,
        //Maxipublica transmisión
        speeds: semiCarInfo.speeds,
        //Maxipublica motor
        consumptionCity: semiCarInfo.consumptionCity,
        consumptionRoad: semiCarInfo.consumptionRoad,
        trim: semiCarInfo.trim,
        cylinders: semiCarInfo.cylinders,
        //MAxipublica Luces
        fogLamps: semiCarInfo.fogLamps,
        fogLightsRear: semiCarInfo.fogLightsRear,
        adjustableLights: semiCarInfo.adjustableLights,
        xenonLights: semiCarInfo.xenonLights
    };
    var price = data.carPrices && data.carPrices.length ? getLatestPrice(data.carPrices) : 0;
    var priceLayaway = data.agency.group.priceLayaway;
    var agencyID = data.agency.id;
    var promos = promoRelations && promoRelations.length > 0 ? promoRelations : [];
    var car = {
        id: data.id,
        modelId: data.id,
        brandName: data.brand,
        modelName: data.model,
        version: data.version,
        price: price,
        priceLayaway: priceLayaway,
        agencyID: agencyID,
        agency: data.agency,
        promos: promos,
        imageCoverUrl: getCoverPhoto(data),
        kms: data.km,
        year: data.year,
        isRecommended: data.isRecommended,
        reserved: !!data.reservated,
        type: data.type,
        ownersCount: data.noduenos,
        location: getLocation(data, true),
        transmission: data.transmission,
        description: data.descriptionTxt,
        vin: data.vin,
        status: data.status
    };
    return {
        car: car,
        carInfo: carInfo,
        images: transformImages(data.semiCarImages),
        details: transformDetails(data.carDetails)
    };
};
export var fecthLocationAndTypeUsedCars = function fecthLocationAndTypeUsedCars(page, perPage, location, typeAuto, brand, model, brandLocation, brandPerLocation, modelPerLocation, filter) {
    var url;
    var offset = page * perPage;
    url = "".concat(API_URL, "/semiCar/external/getFilterCars?location=").concat(location, "&type=").concat(typeAuto, "&offset=").concat(offset);
    if (brandLocation && !modelPerLocation) {
        url = "".concat(API_URL, "/semiCar/external/getFilterCars?location=").concat(brandLocation, "&brand=").concat(brandPerLocation, "&offset=").concat(offset);
    }
    if (brandLocation && modelPerLocation) {
        url = "".concat(API_URL, "/semiCar/external/getFilterCars?location=").concat(brandLocation, "&brand=").concat(brandPerLocation, "&model=").concat(modelPerLocation, "&offset=").concat(offset);
    }
    if (brand && !model) {
        url = "".concat(API_URL, "/semiCar/external/getFilterCars?location=").concat(location, "&type=").concat(typeAuto, "&brand=").concat(brand, "&offset=").concat(offset);
    }
    if (model && brand) {
        url = "".concat(API_URL, "/semiCar/external/getFilterCars?location=").concat(location, "&type=").concat(typeAuto, "&brand=").concat(brand, "&model=").concat(model, "&offset=").concat(offset);
    }
    return axios.get(url).then(function (data) {
        return data.data;
    }).then(function (_ref) {
        var rows = _ref.rows, count = _ref.count;
        var cars = transformData(rows);
        cars.map(function (car) {
            return car.id;
        });
        return Promise.all([Promise.resolve({
                cars: cars,
                count: count
            })]);
    }).then(function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 1), _ref3$ = _ref3[0], cars = _ref3$.cars, count = _ref3$.count;
        return {
            cars: cars,
            count: count
        };
    }).catch(function (error) {
        console.log(error);
        return {
            cars: [],
            count: 0
        };
    });
};
export var fetchUsedCars = function fetchUsedCars(filter, page, perPage) {
    var url = "".concat(API_URL, "/semiCar/query");
    var priceFilter = {
        $between: [filter.price.min, filter.price.max]
    };
    if (filter.price.max >= 1000000) {
        priceFilter = {
            $gte: filter.price.min
        };
    }
    if (filter.monthlyPayment && filter.deposit) {
        var maxPrice = filter.deposit + filter.monthlyPayment * 60;
        priceFilter = {
            $lte: maxPrice
        };
    }
    var body = {
        carPrices: {
            where: {
                price: priceFilter
            }
        },
        semiCarImages: {
            where: {
                type: "ext"
            }
        },
        promoRelations: {
            promos: true
        },
        options: {
            distinct: true
        },
        pagination: {
            perPage: perPage,
            page: page
        }
    };
    var where = {};
    if (filter.brands.length > 0) {
        // where.brand = { $or: filter.brands }
        where['$or'] = filter.brands.map(function (brand) {
            return {
                brand: {
                    $ilike: brand
                }
            };
        });
    }
    if (filter.years.length > 0) {
        where.year = {
            $or: filter.years
        };
    }
    if (filter.model) {
        where.model = {
            $ilike: "%".concat(filter.model, "%")
        };
    }
    if (filter.km.min > 1000 || filter.km.max < 130000) {
        where.km = {
            $between: [filter.km.min, filter.km.max]
        };
    }
    if (filter.bodyTypes.length > 0) {
        where.type = {
            $or: filter.bodyTypes
        };
    }
    if (filter.state) {
        body.addresses = {
            where: {
                state: {
                    $ilike: filter.state
                }
            }
        };
        if (filter.city) {
            body.addresses = {
                where: {
                    city: {
                        $ilike: filter.city
                    }
                }
            };
        }
    }
    body.agencies = {
        addresses: true
    };
    if (where && Object.keys(where).length > 0 && where.constructor === Object) {
        body = _objectSpread({}, body, {
            where: where
        });
    }
    var data = {
        fetchOptions: body,
        order: [['recommended', 'DESC'], ['updatedAt', 'DESC']]
    };
    if (filter.priceOrdering) {
        data = _objectSpread({}, data, {
            priceOrder: filter.priceOrdering
        });
    }
    if (filter.userQuery) {
        data = _objectSpread({}, data, {
            query: filter.userQuery
        });
    }
    return axios.post(url, data).then(function (data) {
        return data.data;
    }).then(function (_ref4) {
        var rows = _ref4.rows, count = _ref4.count;
        var cars = transformData(rows);
        cars.map(function (car) {
            return car.id;
        });
        return Promise.all([Promise.resolve({
                cars: cars,
                count: count
            })]);
    }).then(function (_ref5) {
        var _ref6 = _slicedToArray(_ref5, 1), _ref6$ = _ref6[0], cars = _ref6$.cars, count = _ref6$.count;
        return {
            cars: cars,
            count: count
        };
    });
};
export var fetchSingleCar = function fetchSingleCar(id) {
    var url = "".concat(API_URL, "/semiCar/getOne");
    var filter = {
        where: {
            id: id
        },
        semiCarDescriptions: true,
        maxiPublicaDescriptions: true,
        carPrices: true,
        promoRelations: {
            promos: true
        },
        semiCarImages: true,
        carDetails: true,
        agencies: {
            addresses: true,
            groups: true
        }
    };
    return axios.post(url, filter).then(function (resp) {
        return resp.data;
    }).then(function (carData) {
        var carInfo = transformSingleCar(carData);
        return Promise.all([Promise.resolve(carInfo)]);
    }).then(function (_ref7) {
        var _ref8 = _slicedToArray(_ref7, 1), info = _ref8[0];
        return _objectSpread({}, info, {
            car: _objectSpread({}, info.car)
        });
    });
};
export var fetchCarsForHome = function fetchCarsForHome() {
    var url = "".concat(API_URL, "/semiCar/get");
    var body = {
        semiCarImages: {
            where: {
                type: "ext"
            }
        },
        carPrices: true,
        agencies: {
            addresses: true
        },
        pagination: {
            page: 0,
            perPage: 4,
            order: [["km", "ASC"]]
        }
    };
    return axios.post(url, body).then(function (data) {
        return data.data;
    }).then(function (data) {
        return data.rows;
    }).then(function (carsData) {
        var data = [];
        if (carsData) {
            var cars = transformData(carsData);
            var ids = cars.map(function (car) {
                return car.id;
            });
            data = Promise.all([Promise.resolve(cars)
                /*  getUsedPromos(ids) */
            ]);
        }
        return data;
    }).then(function (_ref9) {
        var _ref10 = _slicedToArray(_ref9, 1), cars 
        /*  promos */
        = _ref10[0];
        if (cars) {
            cars.forEach(function (car) {
                /* const validPromos = promos.filter((promo: any) =>
                  promo.semiCarIDs.includes(car.id)
                );
                car.promos = [...validPromos]; */
            });
            return cars;
        }
        return [];
    });
};
export var fetchRecommendedCars = function fetchRecommendedCars(carType, carPrice) {
    var url = "".concat(API_URL, "/semiCar/get");
    var priceFilter = {
        $between: [carPrice - 30000, carPrice + 30000]
    };
    var body = {
        semiCarImages: {
            where: {
                type: "ext"
            }
        },
        carPrices: {
            where: {
                price: priceFilter
            }
        },
        agencies: {
            addresses: true
        },
        pagination: {
            page: 0,
            perPage: 4
        },
        options: {
            random: true
        }
    };
    if (carType) {
        body = _objectSpread({}, body, {
            where: {
                type: carType
            }
        });
    }
    return axios.post(url, body).then(function (data) {
        return data.data;
    }).then(function (carsData) {
        var cars = transformData(carsData);
        var ids = cars.map(function (car) {
            return car.id;
        });
        return Promise.all([Promise.resolve(cars)
            /* getUsedPromos(ids) */
        ]);
    }).then(function (_ref11) {
        var _ref12 = _slicedToArray(_ref11, 1), cars 
        /* promos */
        = _ref12[0];
        return cars.map(function (car) {
            /* const validPromos = promos.filter((promo: any) =>
              promo.semiCarIDs.includes(car.id)
            ); */
            return _objectSpread({}, car);
        });
    });
};
export var fetchModelsNameByBrands = function fetchModelsNameByBrands(brands) {
    var url = "".concat(API_URL, "/semiCar/get");
    var body = {
        where: {
            brand: {
                $or: brands
            }
        },
        options: {
            attributes: ["model"],
            group: ["model"]
        }
    };
    return axios.post(url, body).then(function (resp) {
        return resp.data;
    }).then(function (data) {
        return data.rows;
    }).then(function (models) {
        return models.map(function (_ref13) {
            var model = _ref13.model;
            return {
                name: model
            };
        });
    });
};
