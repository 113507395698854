import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import rootReducer from "../reducers";
import rootSaga from "../sagas";
import customMiddleware from "../middleware";
import { isClient } from "utils";
import thunk from 'redux-thunk';
var sagaMiddleware = createSagaMiddleware();
var userDataTemplate = {
    fingerprint: "",
    token: "",
    firstName: "",
    lastName: "",
    id: 0,
    role: "",
    email: "",
    phoneNumber: "",
    isLoggedIn: false,
    hasUpdatedData: false,
    paymentOrders: []
};
var middleware = [].concat(_toConsumableArray(customMiddleware), [sagaMiddleware, thunk]);
var composedEnhancers = composeWithDevTools(applyMiddleware.apply(void 0, _toConsumableArray(middleware)));
export default (function () {
    var initialState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var userData = JSON.parse(JSON.stringify(userDataTemplate));
    if (isClient) {
        var localStorage = window.localStorage;
        var token = localStorage.getItem("token");
        var fingerprint = localStorage.getItem("fingerprint");
        var userFirstName = localStorage.getItem("user_firstName");
        var userLastName = localStorage.getItem("user_lastName");
        var userId = localStorage.getItem("user_id") || 0;
        var userRole = localStorage.getItem("user_role");
        var userEmail = localStorage.getItem("user_email");
        var userIsLoggedIn = localStorage.getItem("user_isLoggedIn");
        var userPhone = localStorage.getItem("user_phoneNumber");
        if (token) {
            userData.token = token;
        }
        if (fingerprint) {
            userData.fingerprint = fingerprint;
        }
        userData.firstName = userFirstName || "";
        userData.lastName = userLastName || "";
        userData.id = +userId;
        userData.role = userRole || "";
        userData.email = userEmail || "";
        userData.isLoggedIn = userIsLoggedIn === "true";
        userData.phoneNumber = userPhone || "";
    }
    var externalUserState = initialState.user;
    if (externalUserState.token && !userData.token) {
        userData.token = externalUserState.token;
    }
    if (externalUserState.fingerprint && !userData.fingerprint) {
        userData.fingerprint = externalUserState.fingerprint;
    }
    if (externalUserState.firstName && !userData.firstName) {
        userData.firstName = externalUserState.firstName;
    }
    if (externalUserState.lastName && !userData.lastName) {
        userData.lastName = externalUserState.lastName;
    }
    if (externalUserState.id && !userData.id) {
        userData.id = externalUserState.id;
    }
    if (externalUserState.role && !userData.role) {
        userData.role = externalUserState.role;
    }
    if (externalUserState.email && !userData.email) {
        userData.email = externalUserState.email;
    }
    if (externalUserState.isLoggedIn && !userData.isLoggedIn) {
        userData.isLoggedIn = externalUserState.isLoggedIn;
    }
    if (externalUserState.isLoggedIn && !userData.isLoggedIn) {
        userData.phoneNumber = externalUserState.phoneNumber;
    }
    var finalState = _objectSpread({}, initialState, {
        user: _objectSpread({}, userData)
    });
    var store = createStore(rootReducer, finalState, composedEnhancers);
    sagaMiddleware.run(rootSaga);
    return store;
});
