import numeral from "numeral";
var loadedLibraries = [];
export function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
export function formatPrice(number) {
    var formatString = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "$0,0.00";
    var formattedNumber;
    if (number > 0) {
        formattedNumber = numeral(number).format(formatString);
    }
    else {
        formattedNumber = '...';
    }
    return formattedNumber;
}
export function formatNumber(number) {
    var formatString = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "0,0";
    return numeral(number).format(formatString);
}
export function debounce(func) {
    var waitMilliseconds = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 50;
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
        isImmediate: false
    };
    var timeoutId;
    return function () {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }
        var context = this;
        var doLater = function doLater() {
            timeoutId = undefined;
            if (!options.isImmediate) {
                func.apply(context, args);
            }
        };
        var shouldCallNow = options.isImmediate && timeoutId === undefined;
        if (timeoutId !== undefined) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(doLater, waitMilliseconds);
        if (shouldCallNow) {
            func.apply(context, args);
        }
    };
}
export function loadScript(id, src) {
    if (loadedLibraries.includes(id)) {
        return Promise.resolve(true);
    }
    return new Promise(function (resolve, reject) {
        var scriptEl = document.createElement("script");
        scriptEl.id = id;
        scriptEl.src = src;
        scriptEl.addEventListener("load", function () {
            return resolve(true);
        });
        scriptEl.addEventListener("error", function (e) {
            return reject(e);
        });
        loadedLibraries.push(id);
        document.body.appendChild(scriptEl);
    });
}
export var isClient = typeof window !== "undefined";
