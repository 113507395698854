import _objectSpread from "@babel/runtime/helpers/objectSpread";
import { cotizationIntelimotor as actionTypes } from 'actions/types';
var initialState = {
    getBrands: [],
    getModels: [],
    getModelsError: null,
    getYears: [],
    getYearsError: null,
    getVersions: [],
    getVersionsError: null,
    getAgencies: [],
    agenciesList: [],
    brandId: null,
    modelId: null,
    yearId: null,
    versionId: null,
    agency: null,
    agencyData: null,
    valuation: null,
    userData: null,
    error: null
};
var cotizationIntelimotorReducer = function cotizationIntelimotorReducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
        case actionTypes.COTIZATION_INTELIMOTOR_GET_BRAND:
            return _objectSpread({}, state, {
                getBrands: action.payload
            });
        case actionTypes.COTIZATION_INTELIMOTOR_GET_MODEL:
            return _objectSpread({}, state, {
                getModels: action.payload
            });
        case actionTypes.COTIZATION_INTELIMOTOR_GET_MODEL_ERROR:
            return _objectSpread({}, state, {
                getModelsError: true
            });
        case actionTypes.COTIZATION_INTELIMOTOR_GET_YEAR:
            return _objectSpread({}, state, {
                getYears: action.payload
            });
        case actionTypes.COTIZATION_INTELIMOTOR_GET_YEAR_ERROR:
            return _objectSpread({}, state, {
                getYearsError: true
            });
        case actionTypes.COTIZATION_INTELIMOTOR_GET_VERSION:
            return _objectSpread({}, state, {
                getVersions: action.payload
            });
        case actionTypes.COTIZATION_INTELIMOTOR_GET_VERSION_ERROR:
            return _objectSpread({}, state, {
                getVersionsError: true
            });
        case actionTypes.COTIZATION_INTELIMOTOR_SAVE_BRANDID:
            return _objectSpread({}, state, {
                brandId: action.payload
            });
        case actionTypes.COTIZATION_INTELIMOTOR_SAVE_MODELID:
            return _objectSpread({}, state, {
                modelId: action.payload
            });
        case actionTypes.COTIZATION_INTELIMOTOR_SAVE_YEARID:
            return _objectSpread({}, state, {
                yearId: action.payload
            });
        case actionTypes.COTIZATION_INTELIMOTOR_SAVE_VERSIONID:
            return _objectSpread({}, state, {
                versionId: action.payload
            });
        case actionTypes.COTIZATION_INTELIMOTOR_GET_AGENCIES:
            return _objectSpread({}, state, {
                getAgencies: action.payload
            });
        case actionTypes.COTIZATION_INTELIMOTOR_SAVE_AGENCY:
            return _objectSpread({}, state, {
                agency: action.payload
            });
        case actionTypes.COTIZATION_INTELIMOTOR_CREATE_VALUATION:
            return _objectSpread({}, state, {
                valuation: action.payload
            });
        case actionTypes.COTIZATION_INTELIMOTOR_SAVE_USER:
            return _objectSpread({}, state, {
                userData: action.payload
            });
        case actionTypes.COTIZATION_INTELIMOTOR_SAVE_AGENCIES_LIST:
            return _objectSpread({}, state, {
                agenciesList: action.payload
            });
        case actionTypes.COTIZATION_INTELIMOTOR_SAVE_AGENCY_DATA:
            return _objectSpread({}, state, {
                agencyData: action.payload
            });
        case actionTypes.COTIZATION_CLEAN_ERROR:
            return _objectSpread({}, state, {
                error: null,
                getModelsError: null,
                getYearsError: null,
                getVersionsError: null
            });
        case actionTypes.COTIZATION_CLEAN_MODELS:
            return _objectSpread({}, state, {
                getModels: [],
                modelId: null
            });
        case actionTypes.COTIZATION_CLEAN_YEARS:
            return _objectSpread({}, state, {
                getYears: [],
                yearId: null
            });
        case actionTypes.COTIZATION_CLEAN_VERSIONS:
            return _objectSpread({}, state, {
                getVersions: [],
                versionId: null
            });
        case actionTypes.COTIZATION_INTELIMOTOR_GET_BRAND_ERROR:
        case actionTypes.COTIZATION_INTELIMOTOR_GET_AGENCIES_ERROR:
        case actionTypes.COTIZATION_INTELIMOTOR_CREATE_VALUATION_ERROR:
            return _objectSpread({}, state, {
                error: action.payload
            });
        default:
            return state;
    }
};
export default cotizationIntelimotorReducer;
