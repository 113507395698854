import _defineProperty from "@babel/runtime/helpers/defineProperty";
import axios from "axios";
import { API_URL } from "utils/api";
export var getFavorites = function getFavorites() {
    var url = "".concat(API_URL, "/fav/get");
    return axios.get(url);
};
export var toggleFav = function toggleFav(id, type) {
    var url = "".concat(API_URL, "/fav/toggle");
    var key = type === "new" ? "modelID" : "semiCarID";
    var body = _defineProperty({}, key, id);
    return axios.post(url, body).then(function (resp) {
        return resp.data;
    });
};
