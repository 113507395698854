import _objectSpread from "@babel/runtime/helpers/objectSpread";
import { GET_ALL_CATALOG, GET_ALL_CATALOG_ERROR, LOADING_CATALOG, GET_ALL_STATES, GET_ALL_STATES_ERROR, GET_ALL_CITIES, GET_ALL_CITIES_ERROR } from './catalogActions';
var initialState = {
    vehicleCatalog: [],
    states: [],
    cities: [],
    count: 0,
    noResults: false,
    error: {
        show: false,
        message: ''
    },
    loading: false
};
var catalogReducer = function catalogReducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
        case LOADING_CATALOG:
            return _objectSpread({}, state, {
                loading: true
            });
        case GET_ALL_CATALOG:
            return _objectSpread({}, state, {
                loading: false,
                vehicleCatalog: action.payload.outPut.rows,
                count: action.payload.outPut.count,
                noResults: action.payload.outPut.noResults,
                states: action.payload.outPut.states
            });
        case GET_ALL_STATES:
            return _objectSpread({}, state, {
                loading: false,
                states: action.payload.outPut
            });
        case GET_ALL_CITIES:
            return _objectSpread({}, state, {
                loading: false,
                cities: action.payload.outPut
            });
        case GET_ALL_CATALOG_ERROR:
        case GET_ALL_STATES_ERROR:
        case GET_ALL_CITIES_ERROR:
            return _objectSpread({}, state, {
                loading: false,
                error: {
                    show: true,
                    message: action.payload
                }
            });
        default:
            return state;
    }
};
export default catalogReducer;
