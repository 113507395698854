import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import { checkoutActionTypes as actionTypes } from "actions/types";
var initialState = {
    carType: "newCar",
    car: {},
    color: {},
    paymentMonths: 1,
    downPayment: 0,
    promoOptions: [],
    additionalOptions: [],
    version: {
        name: "",
        id: 0
    },
    newCarStep: {
        text: "version",
        step: 1
    },
    usedCarStep: {
        text: "reserve",
        step: 1
    }
};
var checkoutReducer = function checkoutReducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
        case actionTypes.SET_CAR_TYPE:
            return _objectSpread({}, state, {
                carType: action.payload
            });
        case actionTypes.SET_CAR_INFO:
            return _objectSpread({}, state, {
                car: action.payload
            });
        case actionTypes.SET_CAR_COLOR:
            return _objectSpread({}, state, {
                color: action.payload
            });
        case actionTypes.SET_PAYMENT_MONTHS:
            return _objectSpread({}, state, {
                paymentMonths: action.payload
            });
        case actionTypes.SET_DOWNPAYMENT:
            return _objectSpread({}, state, {
                downPayment: action.payload
            });
        case actionTypes.SET_ADDITIONAL_OPTIONS:
            return _objectSpread({}, state, {
                additionalOptions: action.payload
            });
        case actionTypes.ADD_ADDITIONAL_OPTION:
            {
                var additionalOptions = state.additionalOptions;
                var newOption = action.payload;
                var tempOptions = _toConsumableArray(additionalOptions);
                var existsOption = tempOptions.find(function (option) {
                    return newOption.title === option.title && newOption.price === option.price;
                });
                if (!existsOption) {
                    tempOptions.push(newOption);
                }
                return _objectSpread({}, state, {
                    additionalOptions: tempOptions
                });
            }
        case actionTypes.REMOVE_ADDITIONAL_OPTION:
            {
                var _additionalOptions = state.additionalOptions;
                var optionToRemove = action.payload;
                var _tempOptions = _additionalOptions.filter(function (option) {
                    return optionToRemove.title === option.title && optionToRemove.price === option.price;
                });
                return _objectSpread({}, state, {
                    additionalOptions: _tempOptions
                });
            }
        case actionTypes.ADD_PROMO_OPTION:
            {
                var _promoOptions = state.promoOptions;
                var _newOption = action.payload;
                var _tempOptions2 = _toConsumableArray(_promoOptions);
                var _existsOption = _tempOptions2.find(function (option) {
                    return _newOption.title === option.title && _newOption.price === option.price;
                });
                if (!_existsOption) {
                    _tempOptions2.push(_newOption);
                }
                return _objectSpread({}, state, {
                    promoOptions: _tempOptions2
                });
            }
        case actionTypes.REMOVE_PROMO_OPTION:
            {
                var _promoOptions2 = state.promoOptions;
                var _optionToRemove = action.payload;
                var _tempOptions3 = _promoOptions2.filter(function (option) {
                    return _optionToRemove.title === option.title && _optionToRemove.price === option.price;
                });
                return _objectSpread({}, state, {
                    promoOptions: _tempOptions3
                });
            }
        case actionTypes.SET_ALL_CAR_DATA:
            var _action$payload = action.payload, car = _action$payload.car, carType = _action$payload.carType, color = _action$payload.color, months = _action$payload.months, payment = _action$payload.payment, promoOptions = _action$payload.promoOptions, options = _action$payload.options;
            return _objectSpread({}, state, {
                car: car,
                carType: carType,
                color: color,
                paymentMonths: months,
                downPayment: payment,
                promoOptions: promoOptions,
                additionalOptions: options
            });
        case actionTypes.SET_VERSION:
            return _objectSpread({}, state, {
                version: action.payload
            });
        case actionTypes.SET_NEW_CAR_STEP:
            return _objectSpread({}, state, {
                newCarStep: action.payload
            });
        case actionTypes.SET_USED_CAR_STEP:
            return _objectSpread({}, state, {
                usedCarStep: action.payload
            });
        case actionTypes.RESET_CHECKOUT_STATE:
            return _objectSpread({}, state, {
                newCarStep: {
                    text: "version",
                    step: 1
                },
                usedCarStep: {
                    text: "reserve",
                    step: 1
                },
                version: {
                    name: "",
                    id: 0
                },
                car: {}
            });
        default:
            return state;
    }
};
export default checkoutReducer;
