import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import { searchFilterActionTypes as actionTypes } from "actions/types";
import { MAX_PRICE_CAR, MAX_USED_CAR_KM, MIN_DEPOSIT_CAR, MIN_MONTHLY_PAYMENT, MIN_PRICE_CAR, MIN_USED_CAR_KM } from "utils/constants";
var initialState = {
    brands: [],
    years: [],
    type: "used",
    price: {
        min: MIN_PRICE_CAR,
        max: MAX_PRICE_CAR
    },
    deposit: MIN_DEPOSIT_CAR,
    monthlyPayment: MIN_MONTHLY_PAYMENT,
    km: {
        min: MIN_USED_CAR_KM,
        max: MAX_USED_CAR_KM
    },
    model: "",
    priceOrdering: "",
    userQuery: "",
    city: "",
    state: "",
    bodyTypes: [],
    activePage: 1
};
var searchFilterReducer = function searchFilterReducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
        case actionTypes.SET_PRICE:
            return _objectSpread({}, state, {
                price: action.payload,
                activePage: 1
            });
        case actionTypes.SET_CAR_TYPE:
            return _objectSpread({}, state, {
                type: action.payload,
                brands: [],
                years: [],
                monthlyPayment: MIN_MONTHLY_PAYMENT,
                deposit: MIN_DEPOSIT_CAR,
                model: "",
                km: {
                    min: MIN_USED_CAR_KM,
                    max: MAX_USED_CAR_KM
                },
                price: {
                    min: MIN_PRICE_CAR,
                    max: MAX_PRICE_CAR
                },
                bodyTypes: [],
                city: "",
                activePage: 1
            });
        case actionTypes.SET_MONTHLY_PAYMENT:
            return _objectSpread({}, state, {
                monthlyPayment: action.payload,
                activePage: 1
            });
        case actionTypes.SET_DEPOSIT:
            return _objectSpread({}, state, {
                deposit: action.payload,
                activePage: 1
            });
        case actionTypes.TOGGLE_YEAR:
            {
                var selectedYear = action.payload;
                var years = _toConsumableArray(state.years);
                if (years.includes(selectedYear)) {
                    years = years.filter(function (brand) {
                        return brand !== selectedYear;
                    });
                }
                else {
                    years.push(selectedYear);
                }
                return _objectSpread({}, state, {
                    years: years,
                    activePage: 1
                });
            }
        case actionTypes.TOGGLE_BRAND:
            {
                var brandName = action.payload;
                var brands = _toConsumableArray(state.brands);
                if (brands.includes(brandName)) {
                    brands = brands.filter(function (brand) {
                        return brand !== brandName;
                    });
                }
                else {
                    brands.push(brandName);
                }
                return _objectSpread({}, state, {
                    brands: brands,
                    activePage: 1
                });
            }
        case actionTypes.TOGGLE_BODY_TYPE:
            {
                var bodyType = action.payload;
                var bodyTypes = _toConsumableArray(state.bodyTypes);
                if (bodyTypes.includes(bodyType)) {
                    bodyTypes = bodyTypes.filter(function (brand) {
                        return brand !== bodyType;
                    });
                }
                else {
                    bodyTypes.push(bodyType);
                }
                return _objectSpread({}, state, {
                    bodyTypes: bodyTypes,
                    activePage: 1
                });
            }
        case actionTypes.SET_BRAND:
            {
                return _objectSpread({}, state, {
                    brands: [action.payload]
                });
            }
        case actionTypes.SET_FILTER:
            return _objectSpread({}, state, action.payload);
        case actionTypes.SET_KM:
            return _objectSpread({}, state, {
                km: action.payload,
                activePage: 1
            });
        case actionTypes.SET_MODEL:
            return _objectSpread({}, state, {
                model: action.payload,
                activePage: 1
            });
        case actionTypes.SET_USER_QUERY:
            {
                var userQuery = action.payload;
                if (!userQuery) {
                    return _objectSpread({}, state, {
                        userQuery: userQuery,
                        brands: [],
                        years: [],
                        monthlyPayment: MIN_MONTHLY_PAYMENT,
                        deposit: MIN_DEPOSIT_CAR,
                        model: "",
                        km: {
                            min: MIN_USED_CAR_KM,
                            max: MAX_USED_CAR_KM
                        },
                        price: {
                            min: MIN_PRICE_CAR,
                            max: MAX_PRICE_CAR
                        },
                        bodyTypes: []
                    });
                }
                return _objectSpread({}, state, {
                    userQuery: userQuery,
                    activePage: 1
                });
            }
        case actionTypes.SET_PRICE_ORDERING:
            return _objectSpread({}, state, {
                priceOrdering: action.payload,
                activePage: 1
            });
        case actionTypes.SET_CITY:
            return _objectSpread({}, state, {
                city: action.payload,
                activePage: 1
            });
        case actionTypes.SET_STATE:
            return _objectSpread({}, state, {
                state: action.payload,
                activePage: 1
            });
        case actionTypes.SET_ACTIVE_PAGE:
            return _objectSpread({}, state, {
                activePage: action.payload
            });
        case actionTypes.RESET_ACTIVE_PAGE:
            return _objectSpread({}, state, {
                activePage: 1
            });
        case actionTypes.SET_CAR_BODY_TYPE:
            return _objectSpread({}, state, {
                bodyTypes: [action.payload],
                activePage: 1
            });
        case actionTypes.CLEAR_DEPOSIT:
            return _objectSpread({}, state, {
                deposit: MIN_DEPOSIT_CAR
            });
        case actionTypes.CLEAR_MONTHLY_PAYMENT:
            return _objectSpread({}, state, {
                monthlyPayment: MIN_MONTHLY_PAYMENT
            });
        case actionTypes.CLEAR_MODEL:
            return _objectSpread({}, state, {
                model: ""
            });
        case actionTypes.CLEAR_KM:
            return _objectSpread({}, state, {
                km: {
                    min: MIN_USED_CAR_KM,
                    max: MAX_USED_CAR_KM
                }
            });
        case actionTypes.CLEAR_PRICE:
            return _objectSpread({}, state, {
                price: {
                    min: MIN_PRICE_CAR,
                    max: MAX_PRICE_CAR
                }
            });
        case actionTypes.CLEAR_CAR_BODY_TYPE:
            return _objectSpread({}, state, {
                bodyTypes: [],
                activePage: 1
            });
        case actionTypes.CLEAR_CITY:
            return _objectSpread({}, state, {
                city: ""
            });
        case actionTypes.CLEAR_FILTER:
            return _objectSpread({}, state, {
                brands: [],
                years: [],
                monthlyPayment: MIN_MONTHLY_PAYMENT,
                deposit: MIN_DEPOSIT_CAR,
                model: "",
                km: {
                    min: MIN_USED_CAR_KM,
                    max: MAX_USED_CAR_KM
                },
                price: {
                    min: MIN_PRICE_CAR,
                    max: MAX_PRICE_CAR
                },
                bodyTypes: [],
                city: ""
            });
        default:
            return state;
    }
};
export default searchFilterReducer;
