import _objectSpread from "@babel/runtime/helpers/objectSpread";
import { footerContectTypes as actionTypes } from 'actions/types';
var initialState = {
    sendMail: null
};
var contactFooterReducer = function contactFooterReducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
        case actionTypes.SEND_MAIL:
            return _objectSpread({}, state, {
                sendMail: action.payload
            });
        case actionTypes.RESET_SEND_MAIL:
            return _objectSpread({}, state, {
                sendMail: null
            });
        default:
            return state;
    }
};
export default contactFooterReducer;
