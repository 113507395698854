import _objectSpread from "@babel/runtime/helpers/objectSpread";
import { userActionTypes as actionTypes } from "actions/types";
export var initialState = {
    fingerprint: "",
    token: "",
    firstName: "",
    lastName: "",
    id: 0,
    role: "",
    email: "",
    phoneNumber: "",
    isLoggedIn: false,
    hasUpdatedData: false,
    paymentOrders: []
};
var userReducer = function userReducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _objectSpread({}, initialState);
    var action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
        case actionTypes.STORE_FINGERPRINT:
            return _objectSpread({}, state, {
                fingerprint: action.payload
            });
        case actionTypes.STORE_JWT:
            return _objectSpread({}, state, {
                token: action.payload
            });
        case actionTypes.ON_LOGIN_SUCCESS:
            return _objectSpread({}, state, action.payload, {
                isLoggedIn: true
            });
        case actionTypes.ON_LOGOUT_SUCCESS:
            return _objectSpread({}, state, {
                firstName: "",
                lastName: "",
                id: 0,
                role: "",
                email: "",
                phoneNumber: "",
                isLoggedIn: false,
                hasUpdatedData: false,
                paymentOrders: []
            });
        case actionTypes.ON_LOGIN_TIMEOUT:
            return _objectSpread({}, state, {
                firstName: "",
                lastName: "",
                id: 0,
                role: "",
                email: "",
                phoneNumber: "",
                isLoggedIn: false,
                hasUpdatedData: false,
                paymentOrders: []
            });
        case actionTypes.SET_UPDATED_DATA:
            return _objectSpread({}, state, {
                hasUpdatedData: action.payload
            });
        case actionTypes.SET_PAYMENT_ORDERS:
            return _objectSpread({}, state, {
                paymentOrders: action.payload
            });
        default:
            return state;
    }
};
export default userReducer;
