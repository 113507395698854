import { userActionTypes as actionTypes } from "actions/types";
import Cookies from "universal-cookie";
var userDataLocalStorage = function userDataLocalStorage(store) {
    return function (next) {
        return function (action) {
            if (action.type === actionTypes.ON_LOGIN_SUCCESS) {
                var nextMiddleware = next(action);
                var nextState = store.getState();
                var _nextState$user = nextState.user, firstName = _nextState$user.firstName, lastName = _nextState$user.lastName, id = _nextState$user.id, role = _nextState$user.role, email = _nextState$user.email, isLoggedIn = _nextState$user.isLoggedIn, phoneNumber = _nextState$user.phoneNumber;
                localStorage.setItem("user_firstName", firstName);
                localStorage.setItem("user_lastName", lastName);
                localStorage.setItem("user_id", id);
                localStorage.setItem("user_role", role);
                localStorage.setItem("user_email", email);
                localStorage.setItem("user_isLoggedIn", isLoggedIn);
                localStorage.setItem("user_phoneNumber", phoneNumber);
                var cookies = new Cookies();
                var userData = {
                    firstName: firstName,
                    lastName: lastName,
                    id: id,
                    role: role,
                    email: email,
                    isLoggedIn: isLoggedIn,
                    phoneNumber: phoneNumber
                };
                cookies.remove("userData", {
                    path: "/"
                });
                cookies.set("userData", JSON.stringify(userData), {
                    maxAge: 3600000,
                    path: "/"
                });
                return nextMiddleware;
            }
            else if (action.type === actionTypes.ON_LOGOUT || action.type === actionTypes.ON_LOGIN_TIMEOUT) {
                localStorage.removeItem("user_firstName");
                localStorage.removeItem("user_lastName");
                localStorage.removeItem("user_id");
                localStorage.removeItem("user_role");
                localStorage.removeItem("user_email");
                localStorage.removeItem("user_isLoggedIn");
                localStorage.removeItem("user_phoneNumber");
                var _cookies = new Cookies();
                _cookies.remove("userData", {
                    path: "/"
                });
            }
            return next(action);
        };
    };
};
export default userDataLocalStorage;
