import _objectSpread from "@babel/runtime/helpers/objectSpread";
import { usedCarDetailActionTypes as actionTypes } from "actions/types";
var initialState = {
    images: [],
    car: {},
    carInfo: {},
    details: []
};
var usedCarDetailReducer = function usedCarDetailReducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
        case actionTypes.SET_DATA:
            return _objectSpread({}, action.payload);
        case actionTypes.RESET_DATA:
            return _objectSpread({}, state, {
                images: []
            });
        default:
            return state;
    }
};
export default usedCarDetailReducer;
