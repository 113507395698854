import { userActionTypes } from "actions/types";
import { isClient } from "utils/index";
var fingerprintLocalStorage = function fingerprintLocalStorage(store) {
    return function (next) {
        return function (action) {
            if (action.type === userActionTypes.STORE_FINGERPRINT) {
                var nextMiddleware = next(action);
                var nextState = store.getState();
                if (isClient) {
                    localStorage.setItem("fingerprint", nextState.user.fingerprint);
                }
                return nextMiddleware;
            }
            return next(action);
        };
    };
};
export default fingerprintLocalStorage;
