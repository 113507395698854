import axios from "axios";
import { storeJWT } from "actions/user";
import { API_URL } from "utils/api";
import Cookies from "universal-cookie";
import { isClient } from "utils/index";
var updateTokenCookie = function updateTokenCookie(newToken) {
    if (isClient) {
        var cookies = new Cookies();
        cookies.remove("token", {
            path: "/"
        });
        cookies.set("token", newToken, {
            maxAge: 3600000,
            path: "/"
        });
    }
};
var updateTokenFromHeader = function updateTokenFromHeader(store) {
    axios.interceptors.response.use(function (resp) {
        if (resp.request && resp.request.responseURL === API_URL)
            return resp;
        var currentToken = store.getState().user.token;
        var token = resp.headers.authorization;
        if (!token)
            return resp;
        if (currentToken !== token) {
            store.dispatch(storeJWT(token));
            updateTokenCookie(token);
        }
        return resp;
    });
};
export default function setUpInterceptors(store) {
    updateTokenFromHeader(store);
}
