import _objectSpread from "@babel/runtime/helpers/objectSpread";
import { bbvaActionsType as actionsTypes } from 'actions/types';
;
var initialState = {
    interesTasa: 0
};
var bbvaReducer = function bbvaReducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
        case actionsTypes.TASA_INTERES:
            return _objectSpread({}, state, {
                interesTasa: action.payload
            });
        default:
            return state;
    }
};
export default bbvaReducer;
