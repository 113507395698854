import { combineReducers } from "redux";
import bbvaReducer from './bbvaReducer';
import carQuoteReducer from "./carQuote";
import catalogReducer from "pages/Catalog/catalogReducer";
import checkoutReducer from "./checkout";
import contactFooterReducer from './contectFooter';
import cotizationIntelimotorReducer from './cotizationIntelimotorReducer';
import favoritesReducer from "./favorites";
import insuranceReducer from './insuranceReducer';
import newCarDetailReducer from "./newCarDetail";
import searchFilterReducer from "./searchFilter";
import sellYourCarReducer from './sellYourCar';
import usedCarDetailReducer from "./usedCarDetail";
import userReducer from "./user";
export default combineReducers({
    bbva: bbvaReducer,
    carQuote: carQuoteReducer,
    catalog: catalogReducer,
    checkout: checkoutReducer,
    contactFooter: contactFooterReducer,
    cotizationIntelimotor: cotizationIntelimotorReducer,
    favorites: favoritesReducer,
    insurance: insuranceReducer,
    newCarDetail: newCarDetailReducer,
    searchFilter: searchFilterReducer,
    sellYourCar: sellYourCarReducer,
    usedCarDetail: usedCarDetailReducer,
    user: userReducer
});
