import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { favoritesActionTypes as actionTypes } from "actions/types";
var initialState = {
    semiCarsIDs: [],
    modelsIDs: [],
    year: 2020,
    comparatorId: []
};
var updateArray = function updateArray(array, carId) {
    if (array.includes(carId)) {
        return array.filter(function (id) {
            return carId !== id;
        });
    }
    else {
        return [].concat(_toConsumableArray(array), [carId]);
    }
};
var favoritesReducer = function favoritesReducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
        case actionTypes.FETCH_USER_FAVORITES_COMPLETED:
            return _objectSpread({}, state, {
                semiCarsIDs: action.payload.semiCarsIDs,
                modelsIDs: action.payload.modelsIDs
            });
        case actionTypes.TOGGLE_NEW_CAR_FAVORITE_COMPLETED:
            {
                var modelsIDs = state.modelsIDs;
                var carId = action.payload.id;
                return _objectSpread({}, state, {
                    modelsIDs: updateArray(modelsIDs, carId)
                });
            }
        case actionTypes.TOGGLE_USED_CAR_FAVORITE_COMPLETED:
            {
                var semiCarsIDs = state.semiCarsIDs;
                var _carId = action.payload.id;
                return _objectSpread({}, state, {
                    semiCarsIDs: updateArray(semiCarsIDs, _carId)
                });
            }
        case actionTypes.FETCH_YEAR_COMPLETE:
            {
                // const { semiCarsIDs } = state;
                // const { id: carId } = action.payload;
                return _objectSpread({}, state, {
                    year: action.payload
                });
            }
        case actionTypes.TOGGLR_USED_COMPARATOR_COMPLETE:
            {
                var comparatorId = state.comparatorId;
                var _carId2 = action.payload.id;
                return _objectSpread({}, state, {
                    comparatorId: updateArray(comparatorId, _carId2)
                });
            }
        default:
            return state;
    }
};
export default favoritesReducer;
