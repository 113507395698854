import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import { getLatestPrice } from 'api/usedCars';
import axios from 'axios';
import { API_URL } from 'utils/api';
var transformUser = function transformUser(data) {
    var user = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        age: data.age,
        phoneNumber: data.phoneNumber,
        role: data.role,
        id: data.id,
        gender: data.gender,
        addressID: data.addressID,
        address: {}
    };
    if (data.address) {
        user.address = data.address;
    }
    return user;
};
export var createUser = function createUser(name, lastName, email, password) {
    var url = "".concat(API_URL, "/user/register");
    var body = {
        userData: {
            firstName: name,
            lastName: lastName,
            email: email,
            isCustomer: true,
            password: password
        }
    };
    return axios.put(url, body);
};
export var loginUser = function loginUser(email, password) {
    var url = "".concat(API_URL, "/user/login");
    var body = {
        email: email,
        password: password
    };
    return axios.post(url, body).then(function (resp) {
        return resp.data;
    });
};
export var logoutUser = function logoutUser() {
    var url = "".concat(API_URL, "/user/logout");
    return axios.post(url).then(function (resp) {
        return resp.data;
    });
};
export var getUserData = function getUserData() {
    var url = "".concat(API_URL, "/user/getMyUser");
    return axios.post(url).then(function (resp) {
        return transformUser(resp.data);
    });
};
export var updateUserPersonalData = function updateUserPersonalData(user, address) {
    var url = "".concat(API_URL, "/user/updateMyUser");
    var email = user.email, rest = _objectWithoutProperties(user, ["email"]);
    return axios.post(url, {
        userData: rest,
        addressData: address
    });
};
export var updateUserAccountData = function updateUserAccountData(email, newPassword) {
    var updateEmailUrl = "".concat(API_URL, "/user/updateMyUser");
    var updatePassUrl = "".concat(API_URL, "/user/updateMyPassword");
    return axios.post(updateEmailUrl, {
        userData: {
            email: email
        }
    }).then(function () {
        return axios.post(updatePassUrl, {
            password: newPassword
        });
    }).then(function (resp) {
        return resp.data;
    });
};
export var requestPasswordReset = function requestPasswordReset(email) {
    var url = "".concat(API_URL, "/user/passwordReset/request");
    return axios.post(url, {
        email: email
    });
};
export var validatePasswordResetToken = function validatePasswordResetToken(token) {
    var url = "".concat(API_URL, "/user/passwordReset/validate");
    return axios.post(url, {
        token: token
    }).then(function (resp) {
        return resp.data;
    });
};
export var updatePassword = function updatePassword(token, newPassword) {
    var url = "".concat(API_URL, "/user/passwordReset/update");
    return axios.post(url, {
        token: token,
        newPassword: newPassword
    }).then(function (resp) {
        return resp.data;
    });
};
export var confirmMail = function confirmMail(userID, token) {
    var url = "".concat(API_URL, "/user/confirmEmail");
    return axios.post(url, {
        userID: userID,
        token: token
    }).then(function (resp) {
        return resp.data;
    });
};
export var fbSignup = function fbSignup(accessToken) {
    var url = "".concat(API_URL, "/user/facebook/login?access_token=").concat(accessToken);
    return axios.post(url).then(function (resp) {
        return resp.data;
    });
};
export var googleSignup = function googleSignup(accessToken) {
    var url = "".concat(API_URL, "/user/google/login?access_token=").concat(accessToken);
    return axios.post(url).then(function (resp) {
        return resp.data;
    });
};
export var getMyPaymentOrders = function getMyPaymentOrders() {
    var url = "".concat(API_URL, "/user/getMyPaymentOrders");
    return axios.post(url).then(function (resp) {
        return resp.data;
    });
};
export var getNewCar = function getNewCar(order) {
    var color = order.color;
    var car = {
        id: order.newCar.model.id,
        modelId: order.newCar.model.id,
        modelName: order.newCar.model.name,
        brandName: order.newCar.model.brand,
        version: order.newCar.version,
        year: order.newCar.model.year,
        type: order.newCar.model.type,
        price: getLatestPrice(order.newCar.carPrices),
        imageCoverUrl: color && color.colorImage ? color.colorImage.path : '',
        status: status || null
    };
    return car;
};
export var getUsedCar = function getUsedCar(order) {
    var car = {
        id: order.semiCar.id,
        modelId: order.semiCar.id,
        modelName: order.semiCar.model,
        brandName: order.semiCar.brand,
        year: order.semiCar.year,
        type: order.semiCar.type,
        price: getLatestPrice(order.semiCar.carPrices),
        imageCoverUrl: order.semiCar.cover,
        version: order.semiCar.version,
        kms: order.semiCar.km,
        status: status || null
    };
    return car;
};
var transformCashOrders = function transformCashOrders() {
    var orders = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var type = arguments.length > 1 ? arguments[1] : undefined;
    return orders.map(function (order) {
        var newOrder = {
            id: order.id,
            step: order.step || 'documents',
            carType: type,
            status: order.status || null,
            deliveryType: order.deliveryType,
            plateProcedure: order.plateProcedure || false,
            deliveryDate: order.deliveryDate || null,
            car: type === 'new' ? getNewCar(order) : getUsedCar(order),
            deliveryAddress: {},
            emptyContract: order.emptyContract
        };
        return newOrder;
    });
};
var transformCreditOrders = function transformCreditOrders() {
    var orders = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var type = arguments.length > 1 ? arguments[1] : undefined;
    return orders.map(function (order) {
        var newOrder = {
            id: order.id,
            step: order.step || 'financing',
            carType: type,
            status: order.status || null,
            deliveryType: order.deliveryType,
            plateProcedure: order.plateProcedure || false,
            deliveryDate: order.deliveryDate || null,
            car: type === 'new' ? getNewCar(order) : getUsedCar(order),
            deliveryAddress: {},
            amountToFinance: 0,
            downpayment: 0,
            monthlyIncome: 0,
            monthlyPayment: 0,
            employmentSituation: 'salaried',
            company: '',
            workStartDate: null,
            workPosition: '',
            workAddress: '',
            references: [],
            workPhoneNumber: '',
            applicant: {},
            emptyContract: ''
        };
        return newOrder;
    });
};
export var getPaymentOrdersByUser = function getPaymentOrdersByUser(userID) {
    var newCarPaymentOrderURL = "".concat(API_URL, "/newCarPaymentOrder/get");
    var newCarCreditPaymentOrderURL = "".concat(API_URL, "/newCarCreditPaymentOrder/get");
    var usedCarPaymentOrderURL = "".concat(API_URL, "/semiCarPaymentOrder/get");
    var usedCreditCarPaymentOrderURL = "".concat(API_URL, "/semiCarCreditPaymentOrder/get");
    var newCarReqBody = {
        where: {
            userID: userID
        },
        newCars: {
            models: true,
            carPrices: true
        },
        colors: {
            colorImages: true
        }
    };
    var usedCarReqBody = {
        where: {
            userID: userID
        },
        semiCars: {
            carPrices: true
        }
    };
    return Promise.all([axios.post(newCarPaymentOrderURL, newCarReqBody), axios.post(newCarCreditPaymentOrderURL, newCarReqBody), axios.post(usedCarPaymentOrderURL, usedCarReqBody), axios.post(usedCreditCarPaymentOrderURL, usedCarReqBody)]).then(function (data) {
        var _data = _slicedToArray(data, 4), newCarPaymentOrders = _data[0], newCarCreditPaymentOrders = _data[1], usedCarPaymentOrders = _data[2], usedCarCreditPaymentOrders = _data[3];
        return [newCarPaymentOrders.data, newCarCreditPaymentOrders.data, usedCarPaymentOrders.data, usedCarCreditPaymentOrders.data];
    }).then(function (data) {
        var _data2 = _slicedToArray(data, 4), newCarPaymentOrders = _data2[0], newCarCreditPaymentOrders = _data2[1], usedCarPaymentOrders = _data2[2], usedCarCreditPaymentOrders = _data2[3];
        return {
            cashOrders: [].concat(transformCashOrders(newCarPaymentOrders, 'new'), transformCashOrders(usedCarPaymentOrders, 'used')),
            creditOrders: [].concat(transformCreditOrders(newCarCreditPaymentOrders, 'new'), transformCreditOrders(usedCarCreditPaymentOrders, 'used'))
        };
    });
};
