var env = process.env.RAZZLE_APP_ENV || "DEV";
console.log("ENV: ", env);
var mapEnvApi = {
    DEV: "http://localhost:8000/api",
    // DEV: "https://farrera-backend-production.karlo.io/api",
    PROD: "https://farrera-backend-production.karlo.io/api",
    STAG: "http://3.222.158.53:8000/api"
}; // PROD: "http://18.221.89.43/api"
var mapEnvKotizador = {
    DEV: "http://localhost:8000",
    // DEV: "https://farrera-backend-production.karlo.io",
    STAG: "https://farrera-backend-production.karlo.io",
    PROD: "https://farrera-backend-production.karlo.io"
};
var mapEnvWS = {
    DEV: "https://farrera-backend.karplace.com/",
    STAG: "http://3.222.158.53:8000/",
    PROD: "https://farrera-backend.karplace.com/"
};
var mapHostName = {
    DEV: "localhost:3000",
    // STAG: "staging.karplace.com",
    PROD: "seminuevosfarrera.com",
    STAG: "http://3.211.194.133:3000"
};
var mapSlider = {
    DEV: 1,
    STAG: 1,
    PROD: 1
};
export var ID_GROUP_SLIDER = mapSlider[env];
export var API_URL = mapEnvApi[env];
export var KOTIZADOR_URL = mapEnvKotizador[env];
export var WS_URL = mapEnvWS[env];
export var MAP_API_KEY = "AIzaSyDX2XQYYH95hufUD6ZdzH12U4trHkUiLgw";
export var HOSTNAME = mapHostName[env];
console.log("HOSTNAME: ", HOSTNAME);
export var FB_APP_ID = "226942142346945";
export var GOOGLE_ID = "607391450234-g19g23qve4mag2qlukbm04khbjc30amo.apps.googleusercontent.com";
