export var MIN_PRICE_CAR = 100000;
export var MAX_PRICE_CAR = 1000000;
export var MIN_DEPOSIT_CAR = 45000;
export var MAX_DEPOSIT_CAR = 1000000;
export var MIN_MONTHLY_PAYMENT = 0;
export var MAX_MONTHLY_PAYMENT = 10000;
export var MIN_USED_CAR_KM = 1000;
export var MAX_USED_CAR_KM = 130000;
export var NEW_CAR_INTEREST_RATE = 13.49;
export var USED_CAR_INTEREST_RATE = 16;
export var MAX_DEPOSIT_PERCENTAGE = 0.91;
export var MIN_DEPOSIT_PERCENTAGE = 0.25;
export var USED_CAR_MIN_DEPOSIT_PERCENTAGE = 0.35;
export var CREDIT_COMMISSION_PERCENTAGE = 1.02;
export var DEFAULT_INSURANCE_PERCENTAGE = 1.01;
