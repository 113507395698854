import _objectSpread from "@babel/runtime/helpers/objectSpread";
import { carQuoteActionTypes as actionTypes } from "actions/types";
var initialState = {
    year: "",
    brand: "",
    model: "",
    version: "",
    km: "",
    color: "",
    LAData: {
        brandCode: "",
        modelCode: "",
        versionCode: ""
    },
    email: "",
    zipCode: "",
    phoneNumber: ""
};
var carQuoteReducer = function carQuoteReducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
        case actionTypes.SET_YEAR:
            return _objectSpread({}, state, {
                year: action.payload
            });
        case actionTypes.SET_BRAND:
            return _objectSpread({}, state, {
                brand: action.payload
            });
        case actionTypes.SET_MODEL:
            return _objectSpread({}, state, {
                model: action.payload
            });
        case actionTypes.SET_VERSION:
            return _objectSpread({}, state, {
                version: action.payload
            });
        case actionTypes.SET_VERSION_CODE:
            {
                var LAData = state.LAData;
                return _objectSpread({}, state, {
                    LAData: _objectSpread({}, LAData, {
                        versionCode: action.payload
                    })
                });
            }
        case actionTypes.SET_KM:
            return _objectSpread({}, state, {
                km: action.payload
            });
        case actionTypes.SET_COLOR:
            return _objectSpread({}, state, {
                color: action.payload
            });
        case actionTypes.SET_BASIC_INFO:
            {
                var _action$payload = action.payload, year = _action$payload.year, brand = _action$payload.brand, model = _action$payload.model, _LAData = _action$payload.LAData, email = _action$payload.email, km = _action$payload.km, version = _action$payload.version, zipCode = _action$payload.zipCode, phoneNumber = _action$payload.phoneNumber;
                return _objectSpread({}, state, {
                    year: year,
                    brand: brand,
                    model: model,
                    LAData: _LAData,
                    email: email,
                    km: km,
                    version: version,
                    zipCode: zipCode,
                    phoneNumber: phoneNumber
                });
            }
        case actionTypes.SET_USER_EMAIL:
            return _objectSpread({}, state, {
                email: action.payload
            });
        case actionTypes.SET_ZIP_CODE:
            return _objectSpread({}, state, {
                zipCode: action.payload
            });
        case actionTypes.SET_USER_TELEPHONE:
            return _objectSpread({}, state, {
                phoneNumber: action.payload
            });
        case actionTypes.SET_KOTIZATIONS_ID:
            return _objectSpread({}, state, {
                kotizationsId: action.payload
            });
        default:
            return state;
    }
};
export default carQuoteReducer;
